
/* 
    ����ͨ���豸ģ��(V1.1.0)
    V1.0.0   ʵ��YSTGetClientVersionInfo   YSTMountCloudKey  YSTUnMountCloudKey  YSTSetMobileKeyUI  YSTGetMobileKeyUIStatus  YSTCheckCurrentCloudKeyTokenStatus
*/
import { NetcaPKI1 } from './netcawebsocket.js'
const NetcaPKI = {};
NetcaPKI.YSTGetClientVersionInfo = function (params) {
    var requestQueryParams = {};
    requestQueryParams["appName"] = "YSTCloudKey";
    requestQueryParams["function"] = "YSTGetClientVersion";
    requestQueryParams["param"] = params;
    return NetcaPKI1.SendNetcaCryptoJsonRpcMessage(requestQueryParams);
}
	
NetcaPKI.YSTMountCloudKey = function (params) {
    var requestQueryParams = {}; 
    requestQueryParams["appName"] = "YSTCloudKey";
    requestQueryParams["function"] = "YSTMountCloudKey";
    requestQueryParams["param"] = params;
    return NetcaPKI.SendNetcaCryptoJsonRpcMessage(requestQueryParams);
}

NetcaPKI.YSTUnMountCloudKey = function (params) {
    var requestQueryParams = {}; 
    requestQueryParams["appName"] = "YSTCloudKey";
    requestQueryParams["function"] = "YSTUnMountCloudKey";
    requestQueryParams["param"] = params;
    return NetcaPKI.SendNetcaCryptoJsonRpcMessage(requestQueryParams);
}

NetcaPKI.YSTSetMobileKeyUI = function (params) {
    var requestQueryParams = {}; 
    requestQueryParams["appName"] = "YSTCloudKey";
    requestQueryParams["function"] = "YSTSetMobileKeyUI";
    requestQueryParams["param"] = params;
    return NetcaPKI.SendNetcaCryptoJsonRpcMessage(requestQueryParams);
}

NetcaPKI.YSTGetMobileKeyUIStatus = function (params) {
    var requestQueryParams = {}; 
    requestQueryParams["appName"] = "YSTCloudKey";
    requestQueryParams["function"] = "YSTGetMobileKeyUIStatus";
    requestQueryParams["param"] = params;
    return NetcaPKI.SendNetcaCryptoJsonRpcMessage(requestQueryParams);
}
	
NetcaPKI.YSTCheckCurrentCloudKeyTokenStatus = function (params) {
    var requestQueryParams = {}; 
    requestQueryParams["appName"] = "YSTCloudKey";
    requestQueryParams["function"] = "YSTCheckCurrentCloudKeyTokenStatus";
    requestQueryParams["param"] = params;
    return NetcaPKI.SendNetcaCryptoJsonRpcMessage(requestQueryParams);
}
	
NetcaPKI.YSTCheckCADrivers = function (params) {
    var requestQueryParams = {}; 
    requestQueryParams["appName"] = "YSTCloudKey";
    requestQueryParams["function"] = "YSTCheckCADrivers";
    requestQueryParams["param"] = params;
    return NetcaPKI.SendNetcaCryptoJsonRpcMessage(requestQueryParams);
}
 
NetcaPKI.YSTCloudKeySignedDataSign = function (params) {
    var requestQueryParams = {}; 
    requestQueryParams["appName"] = "YSTCloudKey";
    requestQueryParams["function"] = "YSTCloudKeySignedDataSign";
    requestQueryParams["param"] = params;
    return NetcaPKI.SendNetcaCryptoJsonRpcMessage(requestQueryParams);
}

NetcaPKI.YSTCloudKeyEnvelopedDataDecrypt = function (params) {
    var requestQueryParams = {}; 
    requestQueryParams["appName"] = "YSTCloudKey";
    requestQueryParams["function"] = "YSTCloudKeyEnvelopedDataDecrypt";
    requestQueryParams["param"] = params;
    return NetcaPKI.SendNetcaCryptoJsonRpcMessage(requestQueryParams);
}


NetcaPKI.YSTMountAllCloudKey = function (params) {
    var requestQueryParams = {}; 
    requestQueryParams["appName"] = "YSTCloudKey";
    requestQueryParams["function"] = "YSTMountAllCloudKeyWithNoLogin";
    requestQueryParams["param"] = params;
    return NetcaPKI.SendNetcaCryptoJsonRpcMessage(requestQueryParams);
}

NetcaPKI.YSTCheckDeviceConnectStatus = function (params) {
    var requestQueryParams = {}; 
    requestQueryParams["appName"] = "YSTCloudKey";
    requestQueryParams["function"] = "YSTCheckDeviceConnectStatus";
    requestQueryParams["param"] = params;
    return NetcaPKI.SendNetcaCryptoJsonRpcMessage(requestQueryParams);
}
NetcaPKI.YSTSelectCert = function (params) {
    var requestQueryParams = {};
    requestQueryParams["appName"] = "YSTCloudKey";
    requestQueryParams["function"] = "YSTSelectCert";
    requestQueryParams["param"] = params;
    return NetcaPKI.SendNetcaCryptoJsonRpcMessage(requestQueryParams);
}
export { NetcaPKI };