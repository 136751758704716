<template>
  <div
    class="container"
    ref="container"
  >
    <div class="wain">
      <a-icon
        type="info-circle"
        class="wainIcon"
      />
      <p>须完成企业认证才可以进行投保。为了不影响您的投标投保，请先完成企业认证！<br>企业认证需在工作日时间（9:00-17:00）内提交。工作日提交当日审核完毕，节假日、非工作日时间内提交的依次顺延审核完毕。</p>
    </div>
    <div class="main">
      <PlateTitle
        title="企业认证"
      />
    </div>
    <div class="form">
      <!-- <a-alert
        message="企业认证信息必须与交易中心投标登记使用的企业信息一致"
        type="warning"
        show-icon
      /> -->
      <a-form
        :form="form"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >
        <a-form-item
          label="投标企业名称"
        >
          <a-input
            v-decorator="['companyName', {rules: [{},],}]"
            placeholder="请输入投标企业名称"
          />
        </a-form-item>
        <a-form-item
          label="投标企业统一社会信用代码"
        >
          <div class="creditCode">
            <a-input
              v-decorator="['creditCode', {rules: [{},],}]"
              placeholder="请输入投标企业统一社会信用代码"
            />
          </div>
        </a-form-item>
        <a-form-item
          label="公司地址"
        >
          <a-input
            v-decorator="['appCompanyAddress', {rules: [{},],}]"
            placeholder="请输入公司地址"
          />
        </a-form-item>
        <a-form-item
          label="公司电话"
        >
          <a-input
            v-decorator="['appCompanyPhone', {rules: [{},],}]"
            placeholder="请输入公司电话"
          />
        </a-form-item>
        <a-form-item
          label="法人姓名"
        >
          <a-input
            v-decorator="['legalName', {rules: [{},],}]"
            placeholder="请输入法人姓名"
          />
        </a-form-item>
        <a-form-item
          label="法人证件号"
        >
          <a-input
            v-decorator="['legalCertNo', {rules: [{},],}]"
            placeholder="请输入法人证件号"
          />
        </a-form-item>
        <div class="identify">
          <a-form-item
            label="营业执照有效期"
          >
            <a-date-picker
              v-decorator="['identifyStart']"
              :locale="locale"
              style="width: 150px;"
            />
            <span>至</span>
            <a-date-picker
              :disabled="identifyDisabled"
              v-decorator="['identifyExpiry']"
              :locale="locale"
              style="width: 150px;margin-right: 10px;"
            />
            <a-checkbox
              @change="longTime"
              :checked="defaultLongTime"
            >
              长期
            </a-checkbox>
          </a-form-item>
        </div>
        <a-form-item
          label="企业营业执照"
        >
          <span class="ant-form-text">
            请提供扫描件。支持jpg、png格式文件，文件不能大于5M
          </span>
          <a-upload
            :action="VUE_APP_FIRST_URL+'/api/contract/upload'"
            :data="{'fileUrl':'entCertForm'}"
            list-type="picture-card"
            :file-list="businessFile"
            @preview="handlePreview"
            @change="(value) => handleChange('business',value)"
          >
            <div v-if="businessFile.length < 1">
              <a-icon type="plus" />
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img
              alt="example"
              style="width: 100%"
              :src="previewImage"
            >
          </a-modal>
        </a-form-item>
        <a-form-item
          label="法人身份证"
        >
          <span class="ant-form-text">
            请提供正反面扫描件。支持jpg、png格式文件，文件不能大于5M
          </span>
          <a-upload
            :action="VUE_APP_FIRST_URL+'/api/contract/upload'"
            :data="{'fileUrl':'entCertForm'}"
            list-type="picture-card"
            :file-list="legalFile1"
            @preview="handlePreview"
            @change="(value) => handleChange('legal1', value)"
          >
            <div v-if="legalFile1.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                身份证正面
              </div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img
              alt="example"
              style="width: 100%"
              :src="previewImage"
            >
          </a-modal>
          <a-upload
            :action="VUE_APP_FIRST_URL+'/api/contract/upload'"
            :data="{'fileUrl':'entCertForm'}"
            list-type="picture-card"
            :file-list="legalFile2"
            @preview="handlePreview"
            @change="(value) => handleChange('legal2', value)"
          >
            <div v-if="legalFile2.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                身份证反面
              </div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img
              alt="example"
              style="width: 100%"
              :src="previewImage"
            >
          </a-modal>
        </a-form-item>
        <a-form-item
          label="授权人身份证"
        >
          <span class="ant-form-text">
            请提供正反面扫描件。支持jpg、png格式文件，文件不能大于5M
          </span>
          <a-upload
            :action="VUE_APP_FIRST_URL+'/api/contract/upload'"
            :data="{'fileUrl':'entCertForm'}"
            list-type="picture-card"
            :file-list="authPersonFile1"
            @preview="handlePreview"
            @change="(value) => handleChange('authPerson1', value)"
          >
            <div v-if="authPersonFile1.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                身份证正面
              </div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img
              alt="example"
              style="width: 100%"
              :src="previewImage"
            >
          </a-modal>

          <a-upload
            :action="VUE_APP_FIRST_URL+'/api/contract/upload'"
            :data="{'fileUrl':'entCertForm'}"
            list-type="picture-card"
            :file-list="authPersonFile2"
            @preview="handlePreview"
            @change="(value) => handleChange('authPerson2', value)"
          >
            <div v-if="authPersonFile2.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                身份证反面
              </div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img
              alt="example"
              style="width: 100%"
              :src="previewImage"
            >
          </a-modal>
        </a-form-item>
        <a-form-item
          :wrapper-col="{ span: 12, offset: 10 }"
          class="entBtn"
        >
          <a-button
            type="primary"
            html-type="submit"
          >
            确定并进入下一步
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-modal
      title="登录CA证书"
      :visible="visible"
      @ok="handleCA"
      @cancel="handleDown"
      cancel-text="取消"
      ok-text="CA登录"
      :get-container="()=>$refs.container"
    >
      <!-- 地址 -->
      <a-spin
        :spinning="spinAddress"
      >
        <div class="CAcode">
          <div class="CAlogin">
            <a-icon type="exclamation-circle" />
            <div>请登录您的CA证书，如为颁发的CA证书，无需进行人工审核；<br>如为其他机构颁发的CA证书，人工审核将在1-2个工作日完成</div>
          </div>
          <div>
            <span v-if="caFlag === '-1'">未检测到您的CA证书，请再次尝试或<a @click="handleDown">点击确认</a>进入人工审核</span>
            <span v-if="caFlag === '0'">检测到您登录为非颁发的证书或证书信息不全，<a @click="handleDown">点击确认</a>进入人工审核</span>
            <span v-if="caFlag === '1'">恭喜您，检测到您登录的为颁发的证书，系统自动审核通过，{{ time }}秒后跳转至账户管理</span>
          </div>
        </div>
      </a-spin>
    </a-modal>
    <a-modal
      title="信息认证"
      :visible="visibleMsg"
      @ok="msgOk"
      @cancel="msgDown"
      cancel-text="取消"
      ok-text="确认"
      :get-container="()=>$refs.container"
    >
      <!-- 地址 -->
      <a-spin
        :spinning="spinAddress"
      >
        <div class="CAcode">
          <div class="CAlogin">
            <a-icon type="exclamation-circle" />
            <div>提交信息确认无误后请点击确认</div>
          </div>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import PlateTitle from '@/components/PlateTitle';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import moment from 'moment';
moment.locale('zh-cn');
import { submitApproveInfo } from '@/api/entCertification/entCertification.js';
import { entInfo } from '@/api/entCertification/entCertification.js';
import { checkYSTService } from '@/assets/script/CAjs/caLogin.js';
import { dateFormatDay } from '@/utils/format.js';
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: 'EntCertForm',
  components: {
    PlateTitle,
  },
  data() {
    return {
      time: 2,
      timer: '',
      caFlag: '',
      spinAddress: false,
      visible: false,
      visibleMsg: false,
      locale,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      previewVisible: false,
      identifyDisabled: false,
      defaultLongTime: false,
      previewImage: '',
      file: {},
      basicFile: [],
      businessFile: [],
      authFile: [],
      legalFile1: [],
      legalFile2: [],
      authPersonFile1: [],
      authPersonFile2: [],
      companyInfo: {
        caFlag: '',
        companyName: '',
        creditCode: '',
      },
      VUE_APP_FIRST_URL: '',
    };
  },
  mounted() {
    this.VUE_APP_FIRST_URL = process.env.VUE_APP_FIRST_URL;
    this.entInfo();
    if (this.$route.query && this.$route.query.companyInfo) {
      const companyInfo = JSON.parse(decodeURIComponent(this.$route.query.companyInfo));
      this.companyInfo = { ...companyInfo };
      this.caFlag = this.companyInfo.caFlag;
      this.form.setFieldsValue({
        companyName: this.companyInfo.companyName ? this.companyInfo.companyName : '',
        creditCode: this.companyInfo.certCode ? this.companyInfo.certCode : '',
      });
      if (this.companyInfo.caFlag === '1') {
        this.timer = setInterval(this.countDown, 1000);
      }
    }
  },
  methods: {
    entInfo() {
      entInfo().then(res => {
        const data = res.data.data;
        if (data.identifyStart === null) {
          delete data.identifyStart;
        }
        if (data.identifyExpiry === null) {
          delete data.identifyExpiry;
        }
        if (data.identifyExpiry === 4102358400000) {
          this.identifyDisabled = true;
          this.defaultLongTime = true;
        }
        this.form.setFieldsValue({
          companyName: data.companyName,
          creditCode: data.creditCode,
          appCompanyAddress: data.appCompanyAddress,
          appCompanyPhone: data.appCompanyPhone,
          legalName: data.legalName,
          legalCertNo: data.legalCertNo,
          identifyStart: dateFormatDay(data.identifyStart),
          identifyExpiry: dateFormatDay(data.identifyExpiry),
        });
        if (data.legalPersonCardImage !== null) {
          this.file = {
            businessFile: data.businessLicenseImage,
            legalFile: data.legalPersonCardImage.split(','),
            authPersonFile: data.authorizerCardImage.split(','),
          };
          console.log(this.file);
          this.businessFile = [{
            uid: '001',
            name: 'image.png',
            status: 'done',
            url: this.VUE_APP_FIRST_URL + data.businessLicenseImage,
          }];
          this.legalFile1 = [{
            uid: '002',
            name: 'image.png',
            status: 'done',
            url: this.VUE_APP_FIRST_URL + data.legalPersonCardImage.split(',')[0],
          }];
          this.legalFile2 = [{
            uid: '003',
            name: 'image.png',
            status: 'done',
            url: this.VUE_APP_FIRST_URL + data.legalPersonCardImage.split(',')[1],
          }];
          this.authPersonFile1 = [{
            uid: '004',
            name: 'image.png',
            status: 'done',
            url: this.VUE_APP_FIRST_URL + data.authorizerCardImage.split(',')[0],
          }];
          this.authPersonFile2 = [{
            uid: '005',
            name: 'image.png',
            status: 'done',
            url: this.VUE_APP_FIRST_URL + data.authorizerCardImage.split(',')[1],
          }];
        }

      });
    },
    handleCA() {
      checkYSTService('company');
      this.timer = '';
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 0;
        this.visible = false;
        clearInterval(this.timer);
        this.$router.push({ name: 'user' });
      }
    },
    handleOk() {},
    handleDown() {
      this.visible = false;
    },
    longTime(e) {
      this.defaultLongTime = !this.defaultLongTime; '1';
      if (e.target.checked) {
        this.identifyDisabled = true;
      } else {
        this.identifyDisabled = false;
      }
    },
    msgOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!values.companyName || values.companyName === '') {
            this.$message.warning('请输入投标企业名称');
            return false;
          }
          if (!values.creditCode || values.creditCode === '') {
            this.$message.warning('请输入投标企业统一社会信用代码');
            return false;
          }
          if (!values.appCompanyAddress || values.appCompanyAddress === '') {
            this.$message.warning('请输入公司地址');
            return false;
          }
          if (!values.appCompanyPhone || values.appCompanyPhone === '') {
            this.$message.warning('请输入公司电话');
            return false;
          }
          if (!values.legalName || values.legalName === '') {
            this.$message.warning('请输入法人姓名');
            return false;
          }
          if (!values.legalCertNo || values.legalCertNo === '') {
            this.$message.warning('请输入法人证件号');
            return false;
          }
          if (!values.identifyExpiry || values.identifyExpiry === '') {
            this.$message.warning('请选择营业执照有效期');
            return false;
          }
          if (this.legalFile1.length === 0 || this.legalFile2.length === 0) {
            this.$message.warning('请上传法人身份证');
            return false;
          }
          if (this.authPersonFile1.length === 0 || this.authPersonFile2.length === 0) {
            this.$message.warning('请上传授权人身份证');
            return false;
          }
          const info = this.$store.state.info;
          this.businessFile.forEach(item => {
            if (item.response) {
              this.file.businessFile = item.response.data.url;
            }
          });
          if (!this.file.legalFile) {
            this.file.legalFile = [];
          }
          if (!this.file.authPersonFile) {
            this.file.authPersonFile = [];
          }
          if (this.legalFile1[0].response) {
            this.file.legalFile[0] = this.legalFile1[0].response.data.url;
          }
          if (this.legalFile2[0].response) {
            this.file.legalFile[1] = this.legalFile2[0].response.data.url;
          }
          if (this.authPersonFile1[0].response) {
            this.file.authPersonFile[0] = this.authPersonFile1[0].response.data.url;
          }
          if (this.authPersonFile2[0].response) {
            this.file.authPersonFile[1] = this.authPersonFile2[0].response.data.url;
          }
          const params = {
            customerId: info.id,
            companyName: values.companyName,
            creditCode: values.creditCode,
            appCompanyAddress: values.appCompanyAddress,
            appCompanyPhone: values.appCompanyPhone,
            legalName: values.legalName,
            legalCertNo: values.legalCertNo,
            identifyStart: values.identifyStart,
            identifyExpiry: values.identifyExpiry,
            businessLicenseImage: this.file.businessFile,
            legalPersonCardImage: this.file.legalFile.join(','),
            authorizerCardImage: this.file.authPersonFile.join(','),
            certificationFlag: info.certificationFlag,
          };
          if (this.identifyDisabled) {
            params.identifyExpiry = '2099-12-31';
          }
          submitApproveInfo(params).then(res => {
            if (res.data.status === 'FAIL') {
              this.$message.warning(res.data.errorMsg);
            } else {
              this.$message.success('企业认证提交成功');
              this.$router.push({ name: 'entCertification' });
            }
          });
        }
      });
    },
    msgDown() {
      this.visibleMsg = false;
    },
    // 下一步
    handleSubmit(e) {
      this.visibleMsg = true;
      e.preventDefault();
    },
    // 取消预览
    handleCancel() {
      this.previewVisible = false;
    },
    // 预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 上传
    handleChange(type, { fileList }) {
      if (type === 'business') {
        if (fileList.length > this.businessFile.length) {
          if (!(fileList[fileList.length - 1].type === 'image/jpeg' || fileList[fileList.length - 1].type === 'image/png')) {
            this.$message.warning('请上传正确格式的文件。');
            return false;
          }
        }
        this.businessFile = fileList;
      } else if (type === 'legal1') {
        if (fileList.length > this.legalFile1.length) {
          if (!(fileList[fileList.length - 1].type === 'image/jpeg' || fileList[fileList.length - 1].type === 'image/png')) {
            this.$message.warning('请上传正确格式的文件。');
            return false;
          }
        }
        this.legalFile1 = fileList;
      } else if (type === 'legal2') {
        if (fileList.length > this.legalFile2.length) {
          if (!(fileList[fileList.length - 1].type === 'image/jpeg' || fileList[fileList.length - 1].type === 'image/png')) {
            this.$message.warning('请上传正确格式的文件。');
            return false;
          }
        }
        this.legalFile2 = fileList;
      } else if (type === 'authPerson1') {
        if (fileList.length > this.authPersonFile1.length) {
          if (!(fileList[fileList.length - 1].type === 'image/jpeg' || fileList[fileList.length - 1].type === 'image/png')) {
            this.$message.warning('请上传正确格式的文件。');
            return false;
          }
        }
        this.authPersonFile1 = fileList;
      } else if (type === 'authPerson2') {
        if (fileList.length > this.authPersonFile2.length) {
          if (!(fileList[fileList.length - 1].type === 'image/jpeg' || fileList[fileList.length - 1].type === 'image/png')) {
            this.$message.warning('请上传正确格式的文件。');
            return false;
          }
        }
        this.authPersonFile2 = fileList;
      }
    },
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card {
  margin-top: 8px;
  color: #666;
}
.ant-upload-picture-card-wrapper {
  width: 125px;
}
.container{
  background-color: #fff;
  margin: 30px auto;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  .ant-form-text {
    display: block;
  }
  /deep/ .ant-modal-header {
    border: none;
  }
  /deep/ .ant-modal-footer {
    border: none;
    height: 76px;
    background-color: #F7FAFF;
  }
  /deep/ .ant-modal-body {
    padding: 0 24px;
  }
  /deep/ .ant-spin-container {
    padding-top: 40px;
    padding-bottom: 64px;
    border-top: 1px solid #EBF2F7;
  }
  /deep/ .ant-btn {
    width: 100px;
    height: 36px;
    border-radius: 3px;
    border: 1px solid #007CE2;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-top: 10px;
    color: #007CE2;
  }
  /deep/ .ant-btn-primary {
    background: #007CE2;
    color: #fff;
    margin-right: 14px;
    margin-left: 20px;
  }
}
.wain {
  width: 1100px;
  height: 70px;
  background: #FFFBF3;
  margin: 0 auto;
  margin-top: 30px;
  position: relative;
  .wainIcon {
    position: absolute;
    top: 16px;
    left: 30px;
    width: 12px;
    height: 12px;
    color: #E7B24B;
  }
  p {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #E7B24B;
    margin: 12px;
    margin-left: 53px;
  }
}
.main{
  padding: 40px 0px 20px;
  border-bottom: 1px solid #EBF2F7;
  width: 1100px;
  margin: auto;
}
.form {
  margin-top: 20px;
  /deep/ .ant-alert-warning {
    width: 500px;
    padding: 8px;
    color: #999;
    margin: 0 auto;
    padding-left: 35px;
    span {
      color: #666;
    }
  }
  /deep/ .ant-upload {
    width: 103px;
    height: 103px;
  }
  /deep/ .ant-form-item {
    margin: 20px 0;
    span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .creditCode {
      display: flex;
      flex-direction: column;
    }
    .same {
      line-height: 14px;
      margin-top: 7px;
    }
  }
  /deep/ .ant-form-item label {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-right: 10px;
  }
  .identify {
    span {
      margin: 0 3px;
    }
    /deep/ .ant-input {
      width: 150px;
    }
  }
  /deep/ .ant-input {
    width: 330px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E1E8EF;
    border-radius: 3px;
    padding-left: 8px;
  }
  /deep/ .ant-btn {
    width: 200px;
    height: 44px;
    background: #007CE2;
    border-radius: 4px;
    margin: 40px 0;
    margin-bottom: 60px;
    span {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .entBtn {
    border-top: 1px solid #EBF2F7;
    width: 1080px;
    margin: 0 auto;
  }
}
.CAcode {
  .CAlogin {
    display: flex;
    background: #FFFBF3;
    margin: 0 auto;
    position: relative;
    .anticon {
      position: absolute;
      width: 14px;
      height: 14px;
      color: #E7B24B;
      top: 14px;
      left: 10px;
    }
    div {
      padding: 10px 0px 9px 33px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #E7B24B;
    }
  }
  /deep/ .ant-input {
    width: 330px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #E1E8EF;
    border-radius: 3px;
    padding-left: 8px;
    margin: 15px 0;
  }
}
</style>
