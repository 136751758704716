import $ from './jquery-1.12.4.min.js'
import { NetcaPKI } from './netcaystcloudkey.js'
import { netca_getClientVersionInfo, netca_isInsertKey, netca_signedDataSign } from './netcawebsocket.js'
function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

let g_ticket;
let g_userType;

function InsertCallBack() {

  // 当前插入key 可以开始登录签名
  loginByCertEvent();
}


function UnInsertCallBack() {

  alert('未找到证书介质，请插入一个数字证书介质KEY');

}


function loginByCert() {

  // 检测客户端版本
  netca_getClientVersionInfo(SuccessGetVersionInfoCallBack, FailedGetVersionInfoCallBack);
  // netca_getVersionInfo(SuccessGetVersionInfoCallBack,FailedGetVersionInfoCallBack);

}

function SuccessGetVersionInfoCallBack(res) {
  const versionInfo = res.VersionInfo;
  const version = res.Version;
  console.log('相关驱动已经安装\n版本号为：' + version + '\n版本号信息为：' + versionInfo + '\n版本UID为：' + res.UID);
  netca_isInsertKey('', '', '', InsertCallBack, UnInsertCallBack);
}

function FailedGetVersionInfoCallBack(res) {
  alert('请先安装相关驱动' + res.msg);
}

function SuccessSignedDataSignCallBack(res) {

  const extendVerify = 'verifyBySVS';
  const signValue = res.signValue;
  // frmLogin.signature.value = signValue;
  console.log('步骤3：签名结果提交后台');
  if (g_userType) {
    $.ajax({
      type: 'get',
      async: false,
      dataType: 'json',
      data: { signValue :signValue, source: g_ticket},
      url: '/api/accCertification/certVerify',
      // 对于异步请求，是处理完“主干”的js代码后，才会执行success的，故在success前修改汉tempHandlerObjs即可
      success(doc) {
        if (doc.status === 'SUCCESS') {
          let companyInfo = {
            caFlag: doc.data.caFlag
          }
          if (doc.data.caFlag !== '1') {
            companyInfo.companyName = doc.data.companyName;
            companyInfo.certCode = doc.data.certCode;
          }
          window.location.href = '/entCertForm?companyInfo=' + encodeURIComponent(JSON.stringify(companyInfo));
        } else {
          alert(doc.errorMsg)
        }
      }
    });
  } else {
    $.ajax({
      type: 'get',
      async: false,
      data: { signValue :signValue, source: g_ticket, extendVerify :''},
      url: '/api/crypto/certVerify/login',
      // 对于异步请求，是处理完“主干”的js代码后，才会执行success的，故在success前修改汉tempHandlerObjs即可
      success(doc) {
        $('#showMessageP').html(doc.responseText);
        $('#loadBeford').hide();
        // frmLogin.showMessage.value = doc.responseText;
        if (doc.status === 'SUCCESS') {
          // window.location.href = '/insure/project';
          window.location.href = '/insure/project/view?count=1';
        } else {
          alert(doc.errorMsg)
        }
      },
      error(error) {
        $('#loadBeford').hide();
        $('#showMessageP').html(error.responseText);
        // frmLogin.showMessage.value = error.responseText;
      },
    });
  }
}


function FailedSignedDataSignCallBack(res) {
  alert(res.msg);
}

function StaffLoginByCertEvent() {
  try {

    g_ticket = getRandomByServer(5);
    console.log('步骤1：服务端已产生随机数！');


    console.log('步骤2：对随机数进行签名！');
    // var certID = document.getElementById("certname").value;
    // var signValue = signedDataByCertificate(oCert[certID], ticket, false, "");

    const certEncode = '';

    const type = 'Device';
    const condition = "InValidity='True' && CertType='Signature'&&DeviceType='59'";
    const tbs = utf8_to_b64(g_ticket);
    const useSubjectKeyId = false;
    const useQ7 = false;
    const detached = false;
    const tsaURL = '';
    const includeCertOption = 2;
    netca_signedDataSign(certEncode, type, condition, tbs, useSubjectKeyId, useQ7, tsaURL, detached, includeCertOption, '', SuccessSignedDataSignCallBack, FailedSignedDataSignCallBack);
    // 带原文签名

  } catch (e) {
    alert('登录失败!' + e.description);
    return null;
  }
}


// 步骤2：前端对随机数进行签名，并把签名结果发送到服务端
function loginByCertEvent() {


  /* 如果需要后台进行证书+网关验证 */

	 try {

	    g_ticket = getRandomByServer(5);
      console.log('步骤1：服务端已产生随机数！');


      console.log('步骤2：对随机数进行签名！');
    // var certID = document.getElementById("certname").value;
	     // var signValue = signedDataByCertificate(oCert[certID], ticket, false, "");

    const certEncode = '';

    const type = 'Device';
    // var condition = "InValidity='True' && CertType='Signature'&&DeviceType='71'";
    const condition = "InValidity='True' && CertType='Signature'";
    const tbs = utf8_to_b64(g_ticket);
    const useSubjectKeyId = false;
    const useQ7 = false;
    const detached = false;
    const tsaURL = '';
    const includeCertOption = 2;
    netca_signedDataSign(certEncode, type, condition, tbs, useSubjectKeyId, useQ7, tsaURL, detached, includeCertOption, '', SuccessSignedDataSignCallBack, FailedSignedDataSignCallBack);
    // 带原文签名

  } catch (e) {
    console.log(e);
    alert('登录失败!' + e.description);
    return null;
  }
}

/**
 * 后台产生随机数 2018年2月12日
 *
 * @return
 */
function getRandomByServer(number) {

  let random = ' ';
  $.ajax({
    type: 'get',
    dataType: 'html',
    headers: { 'Accept': 'application/json, text/plain, */*', 'content-type': 'application/json;charset=UTF-8' },
    async: false,
    data: { number },
    url: '/api/crypto/cetRandomCode',
    // 对于异步请求，是处理完“主干”的js代码后，才会执行success的，故在success前修改汉tempHandlerObjs即可
    success(doc) {
      random = doc;
    },
    error(error) {
      alert('后台产生随机数失败：' + error);
    },
  });
  return random;
}

// ////////////////////////////loginWithScanCode  扫码登录///////////////////////////////
// 检测是否安装环境和CA驱动
// export function checkYSTService(type) {
//   g_userType = type
//   const params = {};
   // 驱动版本
//   NetcaPKI.YSTGetClientVersionInfo(params)
//     .Then(function(res) {
//       checkYSTServiceOK(res);
//     })
//     .Catch(function(res) {
       // 连接失败 返回错误
//       alert('当前驱动异常，请检查是否正确安装粤商通驱动');

//     });
// }
export function checkYSTService(type) {
  g_userType = type
  // const params = {};
  // 驱动版本
  // NetcaPKI.YSTGetClientVersionInfo(params)
  //   .Then(function(res) {
      checkYSTServiceOK();
    // })
    // .Catch(function(res) {
      // 连接失败 返回错误
      // alert('当前驱动异常，请检查是否正确安装粤商通驱动');

    // });
}
function checkYSTServiceOK(res) {

  // 检测粤商通驱动版本
  // 需要根据实际情况调整
  // var cmpDriverVersion={
  //     MinVersion0:1,
  //     MinVersion1:2,
  //     MinVersion2:0,
  //     MinVersion3:3
  // };
  //
  // if(checkPluginVersionMustUpdate( res.Version,cmpDriverVersion))
  // {
  //     //版本过低
  //     alert("您的驱动版本过低,请安装最新版本的粤商通驱动");
  //     return;
  // }
  loginByCert();
  // loginWithScanCode();

}

function checkPluginVersionMustUpdate(version, cmpVersion) {
  // 这里根据项目变化

  const MinVersion0 = cmpVersion.MinVersion0;
  const MinVersion1 = cmpVersion.MinVersion1;
  const MinVersion2 = cmpVersion.MinVersion2;
  const MinVersion3 = cmpVersion.MinVersion3;

  const verArray = version.split('.');
  if (verArray.length != 4) {
    return true;
  }

  //
  if (parseInt(verArray[0]) < MinVersion0) {
    return true;
  }

  if (parseInt(verArray[0]) > MinVersion0) {
    return false;
  }

  // 二版本
  if (parseInt(verArray[1]) < MinVersion1) {
    return true;
  }

  if (parseInt(verArray[1]) > MinVersion1) {
    return false;
  }
  // 三版本
  if (parseInt(verArray[2]) < MinVersion2) {
    return true;
  }

  if (parseInt(verArray[2]) > MinVersion2) {
    return false;
  }

  // 四版本
  if (parseInt(verArray[3]) < MinVersion3) {
    return true;
  }

  if (parseInt(verArray[3]) > MinVersion3) {
    return false;
  }

  return false;
}
function loginWithScanCode() {
  const params = {
    accessTokenType: 2,
  };

  NetcaPKI.YSTMountAllCloudKey(params)
    .Then(function() {
      checkSelectCertAndDoCallBack(YSTMountCloudKeyForSignSuccess);
    })
    .Catch(function(res) {
      if (res.status == -5) {
        if (res.ystCloudKeyCode == -200 || res.ystCloudKeyCode === -300 || res.ystCloudKeyCode === -204 || res.ystCloudKeyCode === -205) {
          const path = window.location.href;
          const index = window.location.href.lastIndexOf('/');
          window.location = path.substring(0, index + 1) + 'tipsPage.jsp';
        } else if (res.ystCloudKeyCode == -207) {
          // -207 表示用户取消二维码窗口
          alert('用户取消操作，关闭二维码');
        } else if (res.ystCloudKeyCode == -208) {
          // -208 表示二维码超时
          alert('二维码超时, 请重新扫码连接');
        } else {
          alert(res.msg);
        }
      }
    });
}

function checkSelectCertAndDoCallBack(callbackFun) {

  const selectType = 'Device';
  // 71 coudKeyType
  // 59 app key
  const selectCondition = "InValidity='True' && CertType='Signature'&&(DeviceType='71'||DeviceType='59')";
  const params = {
    cert: {
      type: selectType,
      condition: selectCondition,
    },
  };

  NetcaPKI.YSTSelectCert(params)
    .Then(function(res) {
      // res.certCode 选择到的证书编码
      callbackFun(res.certCode);
    })
    .Catch(function(res) {
      getUserCertByScanCodeFailedCallBack(res);
    });
}
function getUserCertByScanCodeFailedCallBack(res) {
  alert(res.msg);
}

function YSTMountCloudKeyForSignSuccess(useCert) {
  if (useCert == null || useCert == '') {
    alert('先选择证书');
    return;
  }
  g_ticket = getRandomByServer(5);
  const tbs = utf8_to_b64(g_ticket);
  const _useSubjectKeyId = true;
  const _useQ7 = false;
  const _detached = false;
  const SIGNEDDATA_INCLUDE_CERT_OPTION_SELF = 2;// 包含签名证书
  const _includeCertOption = SIGNEDDATA_INCLUDE_CERT_OPTION_SELF;


  const params = {
    cert: { // 证书(CertificateParams)
      encode: useCert,

    },
    data: { // 数据(DataParams)
      text: tbs,
    },
    useSubjectKeyId: _useSubjectKeyId, // 是否使用主体密钥标识符来标识证书，默认为true
    useQ7: _useQ7, // 布尔值	是否使用国密Q7的方式，默认为false
    detached: parseInt(_detached),
    includeCertOption: _includeCertOption, // 整数	包含证书的标识
  };

  NetcaPKI.YSTCloudKeySignedDataSign(params)
    .Then(function(res) {
      SuccessSignedDataSignCallBack(res);
    })
    .Catch(function(res) {
      FailedSignedDataSignCallBack(res);
    });
}

export default { checkYSTService };
